header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #034014; 
    color: white; 
    padding: 10px 20px;
  }
  
  nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .refresh-link {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-family: 'Noto Sans Bengali', Arial, sans-serif;
    font-size: 20px;
  }

  .refresh-link .logo {
    height: 45px; 
    width: auto; 
  }
  
  
  .menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    padding: 0 15px; 
  }
  
  .menu li a {
    text-decoration: none;
    color: white; 
  }
  
  
  @media (max-width: 600px) {
    .menu {
      flex-direction: column;
    }
  }
  