.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-wrap {
    flex: 1;
  }
  
  footer {
    background-color: #034014; 
    color: white;
    text-align: center;
    padding: 10px 0; 
  }

  .site-footer {
    background-color: #034014; 
    color: white; 
    text-align: center;
    padding: 10px 0; 
    margin-top: auto; 
  }
  
  