.recents {
    font: 14px 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}

.recents-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding: 10px;
}
