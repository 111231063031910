.recents {
  font: 14px 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: white;
}

.recents-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding: 10px;
}

.info-box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
  gap: 40px; 
  justify-content: center;
  text-decoration: none;
}

.info-box {
  margin: 10px;
  margin-bottom: 60px;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start; /* Align items at the top */
  align-items: flex-start; /* Ensure the items start from the top */
  padding: 20px;
  border-radius: 20px;
  background-color: #125F0C;
  box-sizing: border-box; 
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add smooth transition */
}

.info-box h3 {
  margin-top: 10px; 
  text-decoration: none; /* Remove text decoration */
  color: white; /* Ensure the text color is clear */
  align-items: center;
  text-align: center;
  width: 100%;
}

.info-box p {
  color: white;
  text-decoration: none; /* Remove text decoration */
  align-items: center;
  width: 100%;
}


.info-box:hover {
  transform: scale(1.05); /* Enlarge the box on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
  cursor: pointer; /* Change cursor to pointer */
}

@media (max-width: 768px) {
  .info-box-container {
      padding: 0 10px; 
  }
}
