.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    color: white;
    background-color: #034014; 
    padding: 20px;
    min-height: 100vh; 
    width: 60%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.about-photo {
    width: 220px; 
    height: auto; 
    border-radius: 10%; 
    margin-bottom: 20px;
}

.about h2, .about h4, .about p {
    margin-bottom: 5px; 
    margin-top: 5px;
}

@media (max-width: 768px) {
    .about-photo {
        width: 100px; 
    }
}
