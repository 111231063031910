.cv-container {
    background-color: #034014; 
    padding: 20px;
}

.cv-container p {
    color: #ffffff; 
}

.cv-container p a {
    color: rgb(69, 166, 69);
}

.pdf-viewer-container {
    height: 800px; 
}

.rpv-core__inner-page {
    background-color: #034014 !important;
}


.rpv-core__text-layer {
    background-color: transparent;
}
