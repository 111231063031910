.contact {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    padding: 30px;
    min-height: 70vh; 
}

.contact h2 {
    margin-bottom: 50px;
}

.contact-icon {
    width: 50px; 
    height: auto; 
    margin-bottom: 20px; 

}