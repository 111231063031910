.homeCV {
    text-align: left;
    padding: 20px;
    color: white;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .homeCV h3 {
    margin-bottom: 0rem;
  }
  
  .experience-list {
    display: flex;
    flex-direction: column;
  }
  
  .experience-box {
    background-color: #7FAF81; 
    border-left: 5px solid rgb(157, 225, 182); 
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .experience-period {
    margin: 0;
    text-align: right;
  }
  
  .experience-description {
    margin-bottom: 1rem;
  }