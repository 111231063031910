.home {
    color: white;
    margin: auto;
}

.home h1 {
    text-align: left;
    color: white;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 110px;
    margin-bottom: 120px;
    font-size: 310%;
}

