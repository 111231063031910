.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    padding: 20px;
}

.login-form input {
    margin: 10px;
    padding: 10px;
    width: 10%;
    box-sizing: border-box;
}

.login-form button {
    margin: 10px;
    padding: 10px;
    width: 5%;
    box-sizing: border-box;
    background-color: #125F0C;
    color: white;
    border: none;
    cursor: pointer;
}